<template>
  <div>
    <breadcrumbs
      :breadcrumb-items="breadcrumbItems"
    />
    <UserToolbar
      v-if="account && Object.keys(account).length !== 0"
      :account="account"
    />
    <b-card>
      <SiteToolbar
        v-if="Object.keys(site).length !== 0"
        :site="site"
        closing
      />
      <hr>
      <div class="table-filters">
        <div class="col-type">
          <b-form-radio-group
            id="type-report"
            v-model="typeStats"
            class="mb-1"
            button-variant="outline-primary"
            :options="optionsRadioLocal"
            buttons
            size="sm"
            name="radios-btn-default"
            @change="changeType"
          />
        </div>
      </div>
      <div
        v-if="loading"
        class="d-flex justify-content-center mb-1"
      >
        <b-spinner label="Loading..." />
      </div>
      <div v-else>
        <b-table
          small
          :items="rows"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          responsive
          striped
          foot-clone
        >
          <template #head(title)>
            {{ $t('sites.articletitle') }}
          </template>
          <template #head(picture)>
            {{ $t('widgets.image') }}
          </template>
          <template #head(clicks)>
            {{ $t('reportsTableHeaders.clicks.amount') }}
          </template>
          <template #cell(title)="data">
            <a
              :href="`${site.domain}${data.item.path}`"
              target="_blank"
            >{{ data.value }}</a>
          </template>
          <template #cell(picture)="data">
            <b-img
              :src="`//api.phoenix-widget.com/wand/200/150/u/${data.item.item_id}.jpg`"
              rounded
              fluid
              alt="profile photo"
            />
          </template>
          <template #cell(clicks)="data">
            {{ new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(data.value) }}
          </template>
          <template #foot(title)="data">
            <strong :data-label="data.label">{{ $t('Records') }}: {{ rows.length }}</strong>
          </template>
          <template #foot(picture)="data">
            <strong :data-label="data.label" />
          </template>
          <template #foot(clicks)="data">
            <strong :data-label="data.label">{{ new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(totalFormat('clicks')) }}</strong>
          </template>
        </b-table>
      </div>
    </b-card>
  </div>
</template>

<script>
import SiteToolbar from '@/views/dashboard/sites/SiteToolbar.vue'
import useJwt from '@/auth/jwt/useJwt'
import {
  BCard,
  BTable,
  BFormRadioGroup,
  BSpinner,
  BImg,
  VBTooltip,
} from 'bootstrap-vue'
import Breadcrumbs from '@/views/ui/breadcrumbs/Breadcrumbs.vue'
import currencyFormatter from '@/utils/currency-formatter'
import getFormattedDate, { getFormattedDateTime } from '@/utils/date-formatter'
import UserToolbar from '@/views/dashboard/users/UserToolbar.vue'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    Breadcrumbs,
    BCard,
    BTable,
    BFormRadioGroup,
    BSpinner,
    BImg,
    UserToolbar,
    SiteToolbar,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      loading: true,
      account: {},
      authUserData: getUserData(),
      pageTitle: '',
      pageSubtitle: '',
      breadcrumbItems: [],
      typeStats: 'day',
      site: [],
      rows: [],
      fields: [
        { key: 'title', label: 'Title' },
        { key: 'picture', label: 'Picture' },
        { key: 'clicks', label: 'Clicks' },
      ],
      sortBy: 'clicks',
      sortDesc: true,
      sortDirection: 'asc',
      currency: currencyFormatter,
      getFormattedDate,
      getFormattedDateTime,
      optionsRadio: [
        { text: 'Today', key: 'day', value: 'day' },
        { text: '3 days', key: '3day', value: '3day' },
        { text: '7 days', key: 'week', value: 'week' },
        { text: '14 days', key: '2week', value: '2week' },
        { text: 'Month', key: 'month', value: 'month' },
        { text: '3 month', key: '3month', value: '3month' },
      ],
      rangePicker: [getFormattedDate(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)), getFormattedDate(new Date())],
    }
  },
  computed: {
    totalAmount() {
      let totalAmount = 0
      this.rows.forEach(i => {
        totalAmount += i.amount
      })
      return totalAmount
    },
    optionsRadioLocal() {
      return this.optionsRadio.map(o => ({
        value: o.value,
        text: this.$t(`stats.${o.key}`),
      }))
    },
  },
  created() {
    this.getUserData()
    this.getSiteData()
    this.getSiteTopArticles()
  },
  methods: {
    totalFormat(field) {
      let total = 0
      this.rows.forEach(i => {
        total += i[field]
      })
      return total
    },
    async getUserData() {
      const responseData = await useJwt.getUser(this.$route.params.userId)
      this.account = responseData.data.account || []
      this.pageTitle = this.account.name
      this.pageSubtitle = this.account.email
      this.setBreabcrumbs()
    },
    async getSiteData() {
      const responseData = await useJwt.getSite(this.$route.params.siteId)
      this.site = responseData.data.site || []
      this.setBreabcrumbs()
    },
    async setBreabcrumbs() {
      this.breadcrumbItems = [
        {
          text: this.$t('page.menu.users'),
          to: '/users',
        },
        {
          text: this.pageTitle,
          to: `/users/${this.$route.params.userId}`,
        },
        {
          text: this.$t('page.menu.sites'),
          to: `/users/${this.$route.params.userId}/sites`,
        },
        {
          text: await this.site.title,
          to: `/users/${this.site.user_id}/sites/${this.site._id}`,
        },
        {
          text: this.$t('TOP articles'),
          to: `/users/${this.site.user_id}/sites/${this.site._id}/toparticles`,
          active: true,
        },
      ]
    },
    async getSiteTopArticles() {
      this.loading = true
      let dateFrom
      let dateTo
      this.rows = null

      const urlSearchParams = new URLSearchParams(window.location.search)
      const urlParams = Object.fromEntries(urlSearchParams.entries())
      if (urlParams.type) {
        this.typeStats = urlParams.type
      }

      const selectedRange = this.rangePicker
      if (Array.isArray(selectedRange)) {
        [dateFrom, dateTo] = selectedRange
      } else {
        [dateFrom, , dateTo] = selectedRange.split(' ')
      }

      const params = {
        from: dateFrom,
        site_id: this.$route.params.siteId,
        to: dateTo,
        type: this.typeStats,
      }
      const responseData = await useJwt.getSiteTopArticles(params)
      this.rows = responseData.data.sitetoparticles || []
      this.loading = false
    },
    changeType() {
      this.$router.replace({
        name: 'dashboard-site-toparticles',
        query: { type: this.typeStats },
      })
      this.getSiteTopArticles()
    },
  },
}
</script>
<style lang="scss">
</style>
<style>
  .table-filters {
    display: flex;
    justify-content: space-between;
  }

  tfoot th {
    background-color: #fff!important;
  }

  .col-picker {
    min-width: 220px;
  }

  @media (max-width: 1400px) {
    .table-filters {
      flex-wrap: wrap;
      margin-bottom: 15px;
    }

    .col-type {
      width: 60%;
    }
  }

  @media (max-width: 992px) {
    .col-type {
      width: 70%;
    }
  }

  @media (max-width: 800px) {
    .col-type {
      width: 100%;
    }
  }
</style>
